import React from 'react';
import { graphql } from 'gatsby';
import {
  BIO, DARK, LARGE, NEWS, TAKE_ACTION, DONATION, SIGNUP, ENDORSEMENT, HOMEPAGE,
} from '@constants';
import {
  Layout, HomepageHero, BasicRichText,
} from '@molecules';
import {
  BioSection, NewsSection, TakeActionSection, DonationSection, SignUpSection, EndorsementSection,
} from '@organisms';
import { setSearchParams, getKeyValue } from '@utils';

const ContentHomepageTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, settings, languageLabels,
  } = data;

  const { locale } = pageContext;
  const { donationDisclaimer, mobileDisclaimer, siteTitle } = settings;
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, metaDescription, metaImage, path, pageContent, disableAlertBar,
    enLanguagePageLinks, pageInOtherLanguages, disableDonationBar,
  } = page || {};
  const {
    title: pageTitle, subtitle, heroMedia, content, homepageSections, heroAction, subheading,
    heroPlayVideoText, heroImagePlaceholder,
  } = pageContent;
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const sectionModules = {
    [BIO]: BioSection,
    [NEWS]: NewsSection,
    [TAKE_ACTION]: TakeActionSection,
    [DONATION]: DonationSection,
    [SIGNUP]: SignUpSection,
    [ENDORSEMENT]: EndorsementSection,
  };

  const searchParams = setSearchParams(location);

  return (
    <Layout
      location={location}
      metaDescription={metaDescription.metaDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle || siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme={DARK}
      settings={settings}
      pageInOtherLanguages={pageInOtherLanguages}
      enLanguagePageLinks={enLanguagePageLinks}
      languageLabels={languageLabels}
      disableDonationBar={disableDonationBar}
      disableAlertBar={disableAlertBar}
      searchParams={searchParams}
    >
      <HomepageHero
        title={pageTitle && pageTitle.title}
        subheading={subheading}
        heroMedia={heroMedia}
        heroAction={heroAction}
        donationDisclaimer={donationDisclaimer}
        mobileDisclaimer={mobileDisclaimer}
        heroPlayVideoText={heroPlayVideoText}
        heroImagePlaceholder={heroImagePlaceholder}
      />
      <div>
        {subtitle && <BasicRichText content={subtitle} size={LARGE} />}
        {content && <BasicRichText content={content} />}
        {homepageSections && homepageSections.map((section, index) => {
          const { id, theme: sectionTheme, sectionType } = section;
          const previousSection = index > 0 && homepageSections[(index - 1)];
          const SectionModule = sectionModules[sectionType];
          const previousSectionDonation = previousSection
            && previousSection.sectionType === DONATION;
          const previousSectionSignup = previousSection
          && previousSection.sectionType === SIGNUP;

          if (SectionModule) {
            return (
              <SectionModule
                {...section}
                key={id}
                sectionTheme={sectionTheme}
                previousSection={previousSection}
                firstSection={index === 0}
                donationDisclaimer={donationDisclaimer}
                mobileDisclaimer={mobileDisclaimer}
                previousSectionDonation={previousSectionDonation}
                previousSectionSignup={previousSectionSignup}
                pageType={HOMEPAGE}
              />
            );
          }
          return (
            <React.Fragment />
          );
        })}
      </div>
    </Layout>
  );
};

export default ContentHomepageTemplate;

export const homepagePageQuery = graphql`
  query ContentHomepageBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(path: { eq: $slug }, language: { eq: $locale }) {
      ...PageMetadata
      pageContent {
        ... on ContentfulPageContentHomepage {
          title {
            title
          }
          subheading {
            raw
          }
          heroMedia {
            mediaType
            imageOrCustomThumbnail {
              file {
                url
                contentType
              }
            }
            mediaUid
            mediaTitle
          }
          heroPlayVideoText
          heroImagePlaceholder {
            description
            file {
              url
            }
          }
          heroAction {
            ... on ContentfulComponentForm {
              submitButtonLabel
              formName
              formId
              googleMacro
              defaultFormSourcing
              formType
              contentfulfields {
                label
                id
                name
                required
                type
                desktopWidth
                mobileWidth
              }
              submitButtonDesktopWidth
              submitButtonMobileWidth
              postSubmitDonationAsk {
                id
                header
                intro {
                  raw
                }
                desktopDonationButtons {
                  key
                  id
                  value
                }
                mobileDonationButtons {
                  key
                  id
                  value
                }
                donationLink
                header
              }
            }
            ... on ContentfulComponentDonationAsk {
              id
              header
              intro {
                raw
              }
              desktopDonationButtons {
                key
                id
                value
              }
              mobileDonationButtons {
                key
                id
                value
              }
              donationLink
              header
            }
          }
          homepageSections {
            sectionTitle
            sectionType
            theme
            id
            summaryContent {
              raw
            }
            readMoreText
            internalReadMoreLink {
              path
            }
            contentReferences {
              ... on ContentfulComponentForm {
                contentful_id
                id
                internal {
                  type
                }
                formId
                defaultFormSourcing
                formType
                submitButtonLabel
                contentfulfields {
                  label
                  name
                  required
                  id
                  type
                  desktopWidth
                  mobileWidth
                  selectradiocheckboxOptions {
                    key
                    value
                  }
                }
                submitButtonDesktopWidth
                submitButtonMobileWidth
                postSubmitDonationAsk {
                  id
                  header
                  intro {
                    raw
                  }
                  desktopDonationButtons {
                    key
                    id
                    value
                  }
                  mobileDonationButtons {
                    key
                    id
                    value
                  }
                  donationLink
                  header
                }
              }
              ... on ContentfulComponentCard {
                id
                externalLink
                internalLink {
                  ... on ContentfulPage {
                    id
                    path
                  }
                }
                title
              }
              ... on ContentfulPageContentNewsItem {
                id
                title
                author
                page {
                  path
                }
                publicationDate(formatString: "MMM D, YYYY")
                publisher: author
                category: publisher
              }
              ... on ContentfulPageContentPolicyDetail {
                id
                titleEyebrow
                title
                page {
                  path
                }
                publicationDate(formatString: "MMM D, YYYY")
                publisher: author
                category: contentTypeLabel
              }
              ... on ContentfulComponentMediaEmbed {
                id
                mediaType
                imageOrCustomThumbnail {
                  fluid(quality: 90, maxWidth: 506) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              ... on ContentfulComponentEndorsement {
                id
                fullName
                title
                endorsementType
                headshotlogo {
                  fluid(quality: 90, maxWidth: 300) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                internalPressReleaseLink {
                  ... on ContentfulPage {
                    id
                    path
                  }
                }
                externalLink
              }
              ... on ContentfulComponentDonationAsk {
                id
                desktopDonationButtons {
                  key
                  id
                  value
                }
                mobileDonationButtons {
                  key
                  id
                  value
                }
                donationLink
                header
              }
            }
          }
        }
      }
    }
    settings: contentfulGlobalSiteSettings(language: { eq: $locale }) {
      ...GlobalSiteSettings
    }
    languageLabels: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
      ...LanguageLabels
    }
  }
`;
